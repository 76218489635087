import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import VueCookie from "vue-cookie";
import VueTheMask from "vue-the-mask";
import Notifications from "vue-notification";
import YmapPlugin from "vue-yandex-maps";
import power from "./plugins/power";
import dark from "./plugins/theme";
import validation from "@/plugins/validation";
import tinymce from "vue-tinymce-editor";

Vue.config.productionTip = false;
const yandexOptions = {
  lang: "ru_RU", // 'ru_RU' by default
  version: "2.1", // '2.1' by default
};
let customVH = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", customVH + "px");
window.addEventListener("resize", () => {
  let customVH = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", customVH + "px");
});

// убрать предупреждения от moment.js
moment.suppressDeprecationWarnings = true;

Vue.use(dark);
Vue.use(power);
Vue.use(YmapPlugin, yandexOptions);
Vue.use(moment);
Vue.use(VueCookie);
Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.use(validation);
Vue.component("tinymce", tinymce);
Vue.prototype.$eventBus = new Vue();
new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
