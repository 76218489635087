import request from "../core/request";

export default {
  getSeo() {
    return request.execute("get", "seo/all");
  },
  getById(id) {
    return request.execute("get", `seo/${id}`);
  },
  saveSeoProduct(input) {
    return request.execute("post", `seo/save/${input.product}/${input.seo}`);
  },
  saveSeoCategory(input) {
    return request.execute(
      "post",
      `seo/save/category/${input.category}/${input.seo}`
    );
  },
  getTags() {
    return request.execute("get", "seo/tags/all");
  },
  saveSeo(input) {
    return request.execute("post", "seo/save", input);
  },
  saveSeoTag(input) {
    return request.execute("post", "seo/save/tag", input.name, {
      "Content-Type": "application/json",
    });
  },
  updateSeo(input) {
    return request.execute("put", `seo/update/${input.id}`, input);
  },
  updateSeoTag(input) {
    return request.execute("put", `seo/update/${input.id}`, input.name, {
      "Content-Type": "application/json",
    });
  },
  deleteSeo(id) {
    return request.execute("delete", `seo/remove/${id}`);
  },
  deleteTag(id) {
    return request.execute("delete", `seo/delete/tag/${id}`);
  },
  keyList(id) {
    return request.execute("get", `admin/seo/key/list/${id}`);
  },
  keySave(data) {
    return request.execute("post", "admin/seo/key/save", data);
  },
  keySingle(id) {
    return request.execute("get", `admin/seo/key/single/${id}`);
  },
  staticList() {
    return request.execute("get", "admin/seo/static/list");
  },
  staticSave(data) {
    return request.execute("post", "admin/seo/static/save", data);
  },
  staticSingle(id) {
    return request.execute("get", `admin/seo/static/single/${id}`);
  },
  productSave(data) {
    return request.execute("post", "admin/seo/product/save", data);
  },
  productSingle(data) {
    return request.execute(
      "get",
      `admin/seo/product/single/${data.cId}/${data.id}`
    );
  },
  productConnection(data) {
    return request.execute("post", "admin/seo/product/connections/save", data);
  },
  categorySave(data) {
    return request.execute("post", "admin/seo/category/save", data);
  },
  categorySingle(data) {
    return request.execute(
      "get",
      `admin/seo/category/single/${data.cId}/${data.id}`
    );
  },
  categoryConnection(data) {
    return request.execute("post", "admin/seo/category/connections/save", data);
  },
  staticKeys() {
    return request.execute("get", "admin/seo/staticKey/list");
  },
};
