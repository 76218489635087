import request from "../core/request";

export default {
  getAllProviders() {
    return request.execute("get", "/admin/market/provider/getAll");
  },
  deleteProvider(providerName) {
    return request.execute(
      "delete",
      `admin/market/provider/delete/${providerName}`
    );
  },
  addProvider(data) {
    return request.execute("post", "/admin/market/provider/add", data);
  },
  getProviderProducts(body) {
    return request.execute(
      "put",
      `admin/moderatorProviderProducts/get/products`,
      body
    );
  },
  getProviderProductsWithFilter(body) {
    return request.execute(
      "post",
      `admin/moderatorProviderProducts/provider`,
      body
    );
  },
  importUpdateFile(data) {
    return request.execute(
      "post",
      `admin/moderatorProviderProducts/upload/excel/${data.providerId}`,
      data.file
    );
  },
  importImages(data) {
    return request.execute(
      "put",
      `/admin/moderatorProviderProducts/put/images/${data.providerId}`,
      data.files
    );
  },
  uploadImages(data) {
    return request.execute(
      "post",
      `admin/provider/product/update/add/image `,
      data
    );
  },
  uploadImageToDelete(data) {
    return request.execute(
      "delete",
      `admin/provider/product/delete/product/image `,
      data
    );
  },
  uploadBarcodes(data) {
    return request.execute(
      "post",
      `/admin/moderatorProviderProducts/check/excel`,
      data
    );
  },
  uploadDescription(data) {
    return request.execute(
      "post",
      `admin/provider/product/update/description`,
      data
    );
  },
  importUpdateFileWithPrices(data) {
    return request.execute(
      "post",
      `admin/ProviderProducts/update/prices/${data.departmentId}/${data.providerId}`,
      data.file
    );
  },
  // закгузка файла с ценами с обнулением остатков
  importUpdateFileWithPricesWithReset(data) {
    return request.execute(
      "post",
      `admin/ProviderProducts/update/prices/reset/${data.departmentId}/${data.providerId}`,
      data.file
    );
  },
  // limits
  getAyanLimits() {
    return request.execute("get", `/admin/provider/order/limit/ayan/get`);
  },
  //   {
  //     "departmentId": 7,
  //     "minOrderSum": 10000
  // }
  addLimitToAyan(body) {
    return request.execute(
      "post",
      `/admin/provider/order/limit/ayan/add`,
      body
    );
  },
  //   {
  //     "providerOrderLimitId": 1,
  //     "minOrderSum": 1000
  // }
  editLimitToAyan(body) {
    return request.execute(
      "put",
      `/admin/provider/order/limit/ayan/edit`,
      body
    );
  },
  // [1, 23] id лимитов
  deleteLimitToAyan(body) {
    return request.execute(
      "delete",
      `/admin/provider/order/limit/ayan/delete`,
      body
    );
  },
  // profile
  //   body:{
  //     "providerId" : 93,
  //     "ayanDeliveryComposition" : true,
  //     "selfDelivery" : true,
  //  }
  createProviderProfile(body) {
    return request.execute(
      "post",
      `/admin/market/provider/profile/create`,
      body
    );
  },
  editProviderProfile(payload) {
    return request.execute(
      "put",
      `admin/market/provider/profile/edit`,
      payload
    );
  },
  getProviderProfile(providerId) {
    return request.execute(
      "get",
      `admin/market/provider/profile/get/${providerId}`
    );
  },
  addImageToProvider(payload) {
    return request.execute(
      "put",
      `admin/market/provider/profile/image/add/${payload.providerId}`,
      payload.body
    );
  },
  //department profile
  createDepartmentProfile(body) {
    return request.execute("post", `/admin/department/profile/create`, body);
  },
  editDepartmentProfile(payload) {
    return request.execute("put", `/admin/department/profile/edit`, payload);
  },
  getDepartmentProfile(depId) {
    return request.execute("get", `/admin/department/profile/${depId}`);
  },
  addImageToDepartment(payload) {
    return request.execute(
      "put",
      `/admin/department/profile/image/add/${payload.providerId}`,
      payload.body
    );
  },
  // дергает список департаментов у которых подключен или нет яндекс
  getYandexList() {
    return request.execute(
      "get",
      `/properties/get/byName/department_with_picker_yandex_auto_assign`
    );
  },
  changeYandexDelivery(depId) {
    return request.execute(
      "post",
      `properties/yandex-couriers/status?departmentId=${depId}`
    );
  },
  // лимиты поставщиков на продукты
  getProviderProductsLimit(providerId) {
    return request.execute(
      "get",
      `admin/provider/product/limit/get?providerId=${providerId}`
    );
  },
  // {"departmentId": 27,"providerId": 247,"measureId": 3, - id единицы измерения
  // "minBalance": 5 - минимальный баланс, с которым товар будет доступен и будет отображаться на сайте.
  addProviderProductsLimit(body) {
    return request.execute("post", `admin/provider/product/limit/add`, body);
  },
  // {"providerProductLimitId": 8, - id лимита"minBalance": 3}
  editProviderProductsLimit(body) {
    return request.execute("put", `admin/provider/product/limit/edit`, body);
  },
  // [1,2,3]- id лимитов
  deleteProviderProductsLimit(body) {
    return request.execute(
      "delete",
      `admin/provider/product/limit/delete`,
      body
    );
  },
  getProviderDepartments(providerId) {
    return request.execute(
      "get",
      `admin/market/provider/get/departments/${providerId}`
    );
  },
  addRequisitsToProvider(body) {
    return request.execute(
      "post",
      `admin/market/provider/add/requisites`,
      body
    );
  },
  changeRequisitsToProvider(body) {
    return request.execute(
      "put",
      `admin/market/provider/edit/requisites`,
      body
    );
  },
};
