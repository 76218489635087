import request from "../core/request";

export default {
  getOrderReport(data) {
    return request.execute("get", "admin/report/generate/ordersByStatus", data);
  },

  fileEdit(data) {
    return request.execute("post", "admin/product/file/edit", data);
  },

  //EXPORT

  exportOrder(data) {
    return request.execute("post", "admin/provider/report/excel/export", data);
  },
  // body : {startTime : Date,endTime : Date,lifeTimePeriods: string,isMonth : boolean}
  getClientsReport(data) {
    return request.execute("post", "admin/client/get/excel", data);
  },
  //Получение списка готовы exel
  getClientsReportsList() {
    return request.execute("get", "admin/client/get/excel/list");
  },
  // body : {startDate : Date,endDate : Date}
  getDistanceReport(data) {
    return request.execute(
      "post",
      "admin/courier/auto/assignee/excel/total",
      data
    );
  },
  getRatingsReport() {
    return request.execute("get", "admin/rating/get/report");
  },
  getProvidersReport(payload) {
    return request.execute(
      "post",
      "admin/provider/report/providers/transfers ",
      payload
    );
  },

  //IMPORT
  /**
   * Отдает путь к Excel файлу со статистикой заказов за определенный период времени
   * @param {Object} data объект с полями начала периода даты и конца периода даты слежения
   * @returns Promise внутри которого объект с полем filename в котором хранится путь к Excel файлу
   */
  reportStatus(data) {
    return request.execute(
      "post",
      "admin/providerOrders/timeTrack/excel",
      data
    );
  },

  reportPost(data) {
    return request.execute("post", "admin/report/", data);
  },

  reportDep(data) {
    return request.execute(
      "post",
      "admin/provider/report/generate/departmentStatistics",
      data
    );
  },

  reportReward_v2(data) {
    return request.execute("post", "admin/order-pack/info/excel", data);
  },
  reportSalesOpen(data) {
    return request.execute(
      "post",
      `admin/provider/report/openReport/${data.departmentId}`,
      data.body
    );
  },
  reportSalesClose(data) {
    return request.execute(
      "post",
      `/admin/provider/report/completeReport/${data.departmentId}`,
      data.body
    );
  },
  reportPlatform(body) {
    return request.execute(
      "post",
      `/admin/provider/report/completePlatformReport`,
      body
    );
  },
  reportEmployeeCompensation(body) {
    return request.execute(
      "post",
      `/admin/provider/report/employeeCompensationReport`,
      body
    );
  },
  downloadInvoiceForOrder(orderId) {
    return request.executeFile("get", `pdf/get/${orderId}`);
  },
  reportAirba(body) {
    return request.execute(
      "post",
      `admin/provider/report/airbapay/providersReport`,
      body
    );
  },
};
