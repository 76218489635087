<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
    <notifications
      position="bottom right"
      group="app"
      :width="300"
      :duration="3000"
      ignore-duplicates
    />
  </div>
</template>
<script>
import DefaultLayout from "@/layouts/default";
import AuthLayout from "@/layouts/auth";
import ChatLayout from "@/layouts/chat";
import AstorLayout from "@/layouts/authASTOR";
export default {
  name: "App",
  components: {
    "default-layout": DefaultLayout,
    "auth-layout": AuthLayout,
    "chat-layout": ChatLayout,
    "astor-layout": AstorLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout
        ? `${this.$route.meta.layout}-layout`
        : "default-layout";
    },
  },
};
</script>
<style>
.notification-title {
  font-size: 1.1rem;
  font-weight: 500;
}
.notification-content {
  font-size: 1rem;
}
</style>
