//import Example from "./modules/example";
import Category from "./modules/category";
import Employee from "./modules/employee";
import Clients from "./modules/clients";
import Address from "./modules/address";
import Label from "./modules/label";
import Logistic from "./modules/logistic";
import Auth from "./modules/auth";
import City from "./modules/city";
import Atypes from "./modules/atypes";
import Measure from "./modules/measure";
import Order from "./modules/order";
import Status from "./modules/status";
import Products from "./modules/product";
import Payment from "./modules/payment";
import Department from "./modules/department";
import DeliveryStatus from "./modules/deliveryStatus";
import Schedule from "./modules/schedule";
import Currency from "./modules/currency";
import Power from "./modules/power";
import Role from "./modules/role";
import RolePower from "./modules/rolePower";
import User from "./modules/user";
import Chat from "./modules/chat";
import Reports from "./modules/reports";
import Seo from "./modules/seo";
import File from "./modules/file";
import Timetable from "./modules/timetable";
import Zone from "./modules/zone";
import Static from "./modules/static";
import Shelf from "./modules/shelf";
import Banner from "./modules/banner";
import Catalog from "./modules/catalog";
import Astor from "./modules/astor";
import Phone from "./modules/phone";
import TimeControl from "./modules/timeControl";
import OrderStatisticsMap from "./modules/orderStatisticsMap";
import FlexParameter from "./modules/flexParameter";
import Features from "./modules/features";
import Vacancy from "./modules/vacancy";
import StackSystem from "./modules/stackSystem";
import Reviews from "./modules/reviews";
import Providers from "./modules/providers";
import Couriers from "./modules/couriers";
import PushNotifications from "./modules/pushNotification";
import DeletedProducts from "./modules/deletedProducts";
import Education from "./modules/education";
import Penalty from "./modules/penalty";

export default {
  StackSystem,
  Category,
  Employee,
  Clients,
  Address,
  Label,
  Logistic,
  Auth,
  City,
  Atypes,
  Measure,
  Order,
  Status,
  Products,
  Payment,
  Penalty,
  PushNotifications,
  Department,
  DeliveryStatus,
  DeletedProducts,
  Schedule,
  Currency,
  Power,
  Role,
  RolePower,
  User,
  Chat,
  Reports,
  Seo,
  File,
  Timetable,
  Zone,
  Static,
  Shelf,
  Banner,
  Catalog,
  Astor,
  Phone,
  TimeControl,
  OrderStatisticsMap,
  FlexParameter,
  Features,
  Vacancy,
  Reviews,
  Providers,
  Couriers,
  Education,
};
