/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "Role";
const namespaced = true;
const state = {
  seoAll: [],
  tagsAll: [],
  seoTypes: [
    { id: 1, name: "Для продуктов(group)" },
    { id: 2, name: "Для статических страниц(url)" },
  ],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_SEO: (state, data) => {
    if (data.type === "success") {
      state.seoAll = data.data;
    }
  },
  SET_TAGS: (state, data) => {
    if (data.type === "success") {
      state.tagsAll = data.data;
    }
  },
};
const actions = {
  GET_SEO: async ({ commit }, payload) => {
    let data = await api.Seo.getSeo(payload);
    commit("SET_SEO", data);
  },
  GET_BY_ID: async ({ commit }, payload) => {
    let data = await api.Seo.getById(payload);
    return data;
  },
  SAVE_SEO: async ({ commit }, payload) => {
    let data = await api.Seo.saveSeo(payload);
    return data;
  },
  SAVE_SEO_TAG: async ({ commit }, payload) => {
    let data = await api.Seo.saveSeoTag(payload);
    return data;
  },
  UPDATE_SEO: async ({ commit }, payload) => {
    let data = await api.Seo.updateSeo(payload);
    return data;
  },
  UPDATE_SEO_TAG: async ({ commit }, payload) => {
    let data = await api.Seo.updateSeoTag(payload);
    return data;
  },
  DELETE_SEO: async ({ commit }, payload) => {
    let data = await api.Seo.deleteSeo(payload);
    return data;
  },
  DELETE_TAG: async ({ commit }, payload) => {
    let data = await api.Seo.deleteTag(payload);
    return data;
  },
  SET_SEO_PRODUCT: async ({ commit }, payload) => {
    let data = await api.Seo.saveSeoProduct(payload);
    return data;
  },
  SET_SEO_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Seo.saveSeoCategory(payload);
    return data;
  },
  GET_TAGS: async ({ commit }, payload) => {
    let data = await api.Seo.getTags(payload);
    commit("SET_TAGS", data);
  },
  KEY_LIST: async ({ commit }, payload) => {
    let data = await api.Seo.keyList(payload);
    if (data.type == "success") {
      return data.data;
    }
    return [];
  },
  KEY_SAVE: async ({ commit }, payload) => {
    let data = await api.Seo.keySave(payload);
    return data;
  },
  KEY_SINGLE: async ({ commit }, payload) => {
    let data = await api.Seo.keySingle(payload);
    if (data.type == "success") {
      return data.data;
    }
    return {};
  },
  STATIC_LIST: async ({ commit }, payload) => {
    let data = await api.Seo.staticList(payload);
    if (data.type == "success") {
      return data.data;
    }
    return [];
  },
  STATIC_SAVE: async ({ commit }, payload) => {
    let data = await api.Seo.staticSave(payload);
    return data;
  },
  STATIC_SINGLE: async ({ commit }, payload) => {
    let data = await api.Seo.staticSingle(payload);
    if (data.type == "success") {
      return data.data;
    }
    return {};
  },
  PRODUCT_SAVE: async ({ commit }, payload) => {
    let data = await api.Seo.productSave(payload);
    return data;
  },
  PRODUCT_SINGLE: async ({ commit }, payload) => {
    let data = await api.Seo.productSingle(payload);
    if (data.type == "success") {
      return data.data;
    }
    return {};
  },
  PRODUCT_CONNECTION: async ({ commit }, payload) => {
    let data = await api.Seo.productConnection(payload);
    if (data.type == "success") {
      return data.data;
    }
    return {};
  },
  CATEGORY_SAVE: async ({ commit }, payload) => {
    let data = await api.Seo.categorySave(payload);
    return data;
  },
  CATEGORY_SINGLE: async ({ commit }, payload) => {
    let data = await api.Seo.categorySingle(payload);
    if (data.type == "success") {
      return data.data;
    }
    return {};
  },
  CATEGORY_CONNECTION: async ({ commit }, payload) => {
    let data = await api.Seo.categoryConnection(payload);
    if (data.type == "success") {
      return data.data;
    }
    return {};
  },
  STATIC_KEYS: async ({ commit }, payload) => {
    let data = await api.Seo.staticKeys(payload);
    if (data.type == "success") {
      return data.data;
    }
    return {};
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
